import { TIconId } from '@nrk/yr-icons';
import { Fragment, useEffect, useRef, useState } from 'react';
import { AnalyticsTrackParameters } from '../../lib/analytics/model/analytics';
import { track } from '../../lib/analytics/track';
import { listenIsScrollableHorizontally } from '../../lib/helpers/scroll';
import './GraphShellNew.scss';
import { GraphShellNew__YAxis } from './GraphShellNew__YAxis';
import { GraphShellNew__YAxisBackground } from './GraphShellNew__YAxisBackground';

interface IProps {
  testId?: string;
  minWidth?: number;
  ariaLabel: string;
  xAxisAriaLabels?: {
    labels: string[];
    activeIndex: number;
    onChange: (parameters: { index: number }) => void;
  };
  rows: Array<IGraphShellNewRow | undefined>;
  hasMinLeftYAxisWidth?: boolean;
  hideScrollShadow?: boolean;
  scrollTrackParameters?: AnalyticsTrackParameters;
  withPadding?: boolean;
}

export interface IGraphShellNewRow {
  yAxisLeft?: IGraphShellNewYAxisTicks | IGraphShellNewYAxisRows;
  yAxisRight?: IGraphShellNewYAxisTicks | IGraphShellNewYAxisRows;
  content?: React.ReactNode;
  hideOnDesktop?: boolean;
}

export interface IGraphShellNewYAxisTicks {
  type: 'ticks';
  ticks: TGraphShellNewYAxisTick[];
}

export interface IGraphShellNewYAxisRows {
  type: 'rows';
  rows: TGraphShellNewYAxisRow[];
}

export type TGraphShellNewYAxisTick = {
  label?: TGraphShellNewYAxisTickValue | TGraphShellNewYAxisTickIcon;
  normalizedY: number;
};

export type TGraphShellNewYAxisRow = {
  label?: TGraphShellNewYAxisTickValue | TGraphShellNewYAxisTickIcon;
  normalizedMinY: number;
  normalizedMaxY: number;
};

type TGraphShellNewYAxisTickValue = {
  type: 'value';
  value: string | number;
};

type TGraphShellNewYAxisTickIcon = {
  type: 'icon';
  id: TIconId;
};

export function GraphShellNew(props: IProps) {
  const {
    testId,
    minWidth,
    rows,
    ariaLabel,
    xAxisAriaLabels,
    hasMinLeftYAxisWidth = false,
    hideScrollShadow = false,
    scrollTrackParameters,
    withPadding = false
  } = props;

  const scrollableGridRef = useRef<HTMLDivElement>(null);

  const [isScrollable, setIsScrollable] = useState({
    left: false,
    right: false
  });

  // State to make sure we only track the first scroll in the graph
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const element = scrollableGridRef.current;
    if (element == null || scrollTrackParameters == null) {
      return;
    }

    // Function that tracks what scrollTrackParameters contains, on scroll
    const handleScrollTrack = () => {
      if (!hasScrolled) {
        track.event(scrollTrackParameters);
        setHasScrolled(true);
      }
    };

    // Attach scroll track function to scrollableGridRef as event listener
    element.addEventListener('scroll', handleScrollTrack);

    // Clean up event listener
    return () => {
      element.removeEventListener('scroll', handleScrollTrack);
    };
  });

  useEffect(() => {
    // The graph shell is never scrollable if we don't have a minimum width
    if (minWidth == null) {
      return;
    }

    const element = scrollableGridRef.current;
    if (element == null) {
      return;
    }

    const cancel = listenIsScrollableHorizontally(element, newIsScrollable => {
      setIsScrollable(newIsScrollable);
    });

    return cancel;
  }, [minWidth, scrollableGridRef]);

  useEffect(() => {
    if (minWidth == null) {
      return;
    }
  }, [minWidth]);

  function handleXAxisAriaLabelsChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (xAxisAriaLabels == null) {
      return;
    }

    xAxisAriaLabels.onChange({ index: Number(event.target.value) });
  }

  const hasLeftYAxis = rows.some(row => row?.yAxisLeft != null);
  const hasRightYAxis = rows.some(row => row?.yAxisRight != null);

  return (
    <figure
      className="graph-shell-new"
      data-testid={testId}
      data-is-scrollable={minWidth != null}
      data-padding={withPadding}
      // If we have x axis aria labels we use the aria label
      // in a figcaption element instead.
      aria-label={xAxisAriaLabels == null ? ariaLabel : undefined}
      role={xAxisAriaLabels == null ? 'img' : undefined}
    >
      {/*
        The aria labels can be accessed by focusing and interacting with the input range.
        Inspired by the scrubber in the Ludo video player made by NRK.
      */}
      {xAxisAriaLabels != null && (
        <>
          {/*
            Ideally we'd use <label> here but Chrome on Android has a bug where
            the screenreader reads out the label instead of the `aria-valuetext`.
            This figcaption should be the first element within the figure element.
          */}
          <figcaption className="nrk-sr">{ariaLabel}</figcaption>

          <input
            className="graph-shell-new__aria-labels"
            aria-valuetext={xAxisAriaLabels.labels[xAxisAriaLabels.activeIndex]}
            type="range"
            min="0"
            max={xAxisAriaLabels.labels.length - 1}
            value={xAxisAriaLabels.activeIndex}
            step="1"
            onChange={handleXAxisAriaLabelsChange}
          />
        </>
      )}

      <div ref={scrollableGridRef} className="graph-shell-new__grid" aria-hidden={true}>
        {/*
          The y axis background columns are useful for adding a little space at the start or end
          of the graph in situations where we have no visible left or right y axis.
          This is only relevant for the larger scrollable graphs, however, and not small graphs
          in cards where we don't want to waste space with an empty y axis column.
        */}
        {(hasLeftYAxis || minWidth != null) && (
          <GraphShellNew__YAxisBackground
            position="left"
            gridColumn={1}
            gridRowEnd={rows.length + 1}
            showScrollShadow={isScrollable.left && hideScrollShadow === false}
            // Use the same min width as the y axis
            hasMinWidth={hasMinLeftYAxisWidth}
          />
        )}

        {(hasRightYAxis || minWidth != null) && (
          <GraphShellNew__YAxisBackground
            position="right"
            gridColumn={3}
            gridRowEnd={rows.length + 1}
            showScrollShadow={isScrollable.right && hideScrollShadow === false}
            hasMinWidth={false}
          />
        )}

        {rows.map((row, index) => {
          if (row == null) {
            return null;
          }

          const gridRow = index + 1;

          return (
            <Fragment key={index}>
              {row.yAxisLeft != null && (
                <GraphShellNew__YAxis
                  position="left"
                  gridColumn={1}
                  gridRow={gridRow}
                  yAxis={row.yAxisLeft}
                  // If we use multiple GraphShellNew and they show different graphs we need to set a min-width on the left y-axis.
                  // We need this because the y-axis only takes the space it needs so if the ticks vary in size the graph will move thereafter.
                  // If we want to align it correctly we need the y-axis to be the same width.
                  hasMinWidth={hasMinLeftYAxisWidth}
                  hideOnDesktop={row.hideOnDesktop}
                />
              )}

              <div
                className="graph-shell-new__graph-content"
                style={{ minWidth, gridColumn: 2, gridRow }}
                data-hide-on-desktop={row.hideOnDesktop}
              >
                {row.content != null ? row.content : <div />}
              </div>

              {row.yAxisRight != null && (
                <GraphShellNew__YAxis
                  position="right"
                  gridColumn={3}
                  gridRow={gridRow}
                  yAxis={row.yAxisRight}
                  hasMinWidth={false}
                  hideOnDesktop={row.hideOnDesktop}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </figure>
  );
}
