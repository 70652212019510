import './ScrollShadow.scss';

interface IProps {
  position: 'left' | 'right';
  visible?: boolean;
}

export function ScrollShadow(props: IProps) {
  const { position, visible = true } = props;

  return <div className={`scroll-shadow scroll-shadow--${position}`} hidden={!visible} />;
}
